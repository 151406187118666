<template>
  <div id="unitsContainer">
    <!--    {{unitsData}}-->
    <div v-if="unitsData">


      <vs-table :data="unitsData" @selected="showUnitsDetails">
        <template slot="header">
          <vs-navbar class="nabarx">
            <div slot="title">
              <vs-navbar-title class="py-4">
                Enote
              </vs-navbar-title>
            </div>
          </vs-navbar>
        </template>
        <template slot="thead">
          <vs-th>
          <!-- empty -->
          </vs-th>
          <vs-th>
            Naziv
          </vs-th>
          <vs-th>
            Občina/-e
          </vs-th>
          <vs-th>
            Koordinator
          </vs-th>
          <vs-th>
            Skrbnik
          </vs-th>
          <vs-th>
            UPN obrazec
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="index" v-for="(tr, index) in data" v-if="data[index].disabled === 0" :data="tr">
            <vs-td :data="data[index].name">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                <vs-avatar size="large" :text="data[index].name.substr(0,1) + data[index].name.match(/\d/g).join('')"/>
              </vs-col>
            </vs-td>

            <vs-td :data="data[index].name">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                {{data[index].name}}
              </vs-col>
            </vs-td>

            <vs-td :data="data[index].coveredMunicipalities">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                {{data[index].coveredMunicipalities}}
              </vs-col>
            </vs-td>

            <vs-td :data="data[index].volunteers">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                <vs-avatar size="large" :src="$globalFunctions.getUserAvatar(data[index].volunteers.profile_image)"/>
                <div class="ml-2">{{data[index].volunteers.first_name + ' ' + data[index].volunteers.last_name}}</div>
              </vs-col>
            </vs-td>

            <vs-td :data="data[index].managedBy">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                <vs-avatar size="large" :src="$globalFunctions.getUserAvatar(data[index].managedBy.profile_image)"/>
                <div class="ml-2">{{data[index].managedBy.first_name + ' ' + data[index].managedBy.last_name}}</div>
              </vs-col>
            </vs-td>

            <vs-td :data="data[index].id">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                <vs-button color="primary" icon="cloud_download" size="small" @click.stop="downloadUPN(data[index])">
                  Prenesi
                </vs-button>
              </vs-col>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <div id="parentx">
      <vs-sidebar position-right parent="body" default-index="1" color="primary" class="unitSidebar" spacer
                  v-model="sidebarActive">

        <Unit v-if="unitId && sidebarActive" :unitId="unitId" />
      </vs-sidebar>
    </div>

  </div>
</template>

<script>
  import Vue from 'vue';
  import Unit from "../../components/Sopotniki/units/Unit";


  export default {
    name: 'units',

    components: {
      Unit
    },

    data() {
      return {
        unitsData: null,
        unitId: null,
        sidebarActive: false
      }
    },

    computed: {

    },

    mounted() {
      const _this = this;
      _this.getUnits();
    },

    beforeRouteLeave(to, from, next) {
      console.log("beforeRouteLeave");
      const _this = this;
      _this.unitId = null;
      _this.sidebarActive = false;
      setTimeout(() => {next()}, 100);
    },

    methods: {
      getUnits() {
        const _this = this;

        _this.$vs.loading();


        Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'units')
          .then((res) => {

            _this.unitsData = res.data.data;
            console.log("TOLE: ", res.data.data);


            _this.$vs.loading.close();


          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },

      showUnitsDetails(tr) {
        const _this = this;

        console.log("HERE!", tr);

        _this.unitId = parseInt(tr.id);
        _this.sidebarActive = true;
      },

      getUpnUrl (unit) {
        return require('@/assets/images/content/upn/upn_' + unit + '.pdf');
      },

      downloadUPN(tr) {
        const _this = this;

        console.log("HERE!", tr);
        let link = document.createElement('a');
        link.href = _this.getUpnUrl(tr.name.toLowerCase());
        console.log(link.href);
        link.download = 'upn_' + tr.name.toLowerCase() + '.pdf';
        link.dispatchEvent(new MouseEvent('click'));
      }

    }
  }
</script>

